.finder-utility{
    &-user-card{
        border: 6px solid $gray;
        padding: 14px 8px;
        border-radius: 12px;
        @media (min-width: 380px) {
          padding: 14px 24px;
        }
        @media (min-width: 600px) {
          padding: 14px 30px;
        }
        @media (min-width: 800px) {
          padding: 14px 60px;
        }
        @media (min-width: 1200px) {
          padding: 14px 16px;
        }
    }
    &-filter{
        .ant-select-selection--single{
            border: 0 !important;
            &:focus{
              border: 0 !important;
            }
          }
          .ant-select-selection-selected-value{
            font-weight: 500;
            font-size: 16px !important;
          }

    }
}

.display-stars{
  .ant-rate-star:not(:last-child){
    margin-right: 0 !important;
  }
  .ant-rate{
    font-size: 34px;
  }
}

.keyMetric-list{
  .ant-list-item{
    padding: 8px 6px 8px 22px !important;
  }
  .list-1{
    border-radius: 14px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
    flex: 1;
  }
  .list-2{
    border-radius: 14px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    flex: 1;
  }

}

.finder-utility-header{
  @media (max-width: 1201px) {
    overflow-x: hidden;
  }
  .ant-layout-header{
    img{
      @media (max-width: 1201px) {
        z-index: 0 !important;
      }
    }
  }
  header{
    @media (max-width: 1201px) {
      justify-content: center;
      padding-bottom: 10px;
    }
  }
  .header-actions{
    @media (max-width: 1201px) {
      text-align: center !important;
    }
    @media (min-width: 1201px) {
      float: right !important;
      padding-right: 100px !important;
    }
  }
  .header-title{
    @media (max-width: 1201px) {
      display: none !important;
    }
  }
  .default-body{
    flex: 1;
    width: 100%;
    @media (max-width: 1201px) {
      padding-left: 230px !important;
      padding-right: 230px !important;
    }
    @media (max-width: 900px) {
      padding-left: 130px !important;
      padding-right: 130px !important;
    }
    @media (max-width: 700px) {
      padding-left: 60px !important;
      padding-right: 60px !important;
    } 
    @media (max-width: 500px) {
      padding-left: 10px !important;
      padding-right: 10px !important;
    } 
    @media (max-width: 300px) {
      padding-left: 5px !important;
      padding-right: 5px !important;
    } 
  }

}

.finder-content{
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  gap: 16px;
  @media (max-width: 1000px) {
    overflow-x: hidden;
  } 
  .finder-filter{
    min-width: 240px;
  }
  .total-loans{
    font-weight: 500;
    font-size: 32px !important;
  }
}

.finder-menu-icon{
  border: 1px solid $success;
  color: $success !important;
  font-size: 16px;
  padding: 4px;
  border-radius: 4px;
}