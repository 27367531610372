.global-search{
    width: 190px;
    @media (min-width: 450px) {
      width: 300px;
    }
  }
.content{
    @media (max-width: 340px) {
        padding-left: 4px !important;
        padding-right: 4px !important;
      }
}

.settings-dropdown-icon {
  height: 62px;
  width: 52px;
  padding-top: 25px;
}