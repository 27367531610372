// Ant.Design Elements Override
::selection {
  background: $primary;
}

// [ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     display: block;
//     border-radius: inherit;
//     box-shadow: 0 0 0 0 $primary;
//     box-shadow: 0 0 0 0 $primary;
//     box-shadow: 0 0 0 0 rgba($primary, 1);
//     opacity: 0.2;
//     -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
//     animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
//     -webkit-animation-fill-mode: forwards;
//     animation-fill-mode: forwards;
//     pointer-events: none;
//     content: '';
// }

.onFocus {
  border-color: $primary;
  box-shadow: 0 0 0 2px rgba($primary, 0.3);
}

.ant {
  &-typography {
    a {
      color: $primary;

      &:hover {
        color: darken($primary, 10%);
      }
    }
  }

  &-select {
    &-selection {
      font-weight: 400;
      font-size: 14px;

      &:hover,
      &:focus {
        @extend .onFocus;
      }
    }

    &-dropdown {
      &-menu {
        &-item {
          &:hover {
            background-color: lighten($primary, 20%);
          }

          &-active {
            background-color: lighten($primary, 20%);
          }
        }
      }
    }
  }

  &-btn {
    font-size: 14px;

    border-color: $gray;
    color: $defaultFontColor;

    &:hover,
    &:active,
    &:focus {
      border-color: $gray;
      color: $primary;
    }

    &-primary {
      background-color: $primary;
      border-color: $primary;
      color: $white;

      &:hover,
      &:active,
      &:focus {
        color: $white$mgod;
        background-color: darken($primary, 10%) $mgod;
        border-color: darken($primary, 10%) $mgod;
      }
      &-disabled,
      &.disabled,
      &[disabled] {
        color: rgba($black, 0.25) $mgod;
        &:hover,
        &:active,
        &:focus,
        &::selection {
          background-color: $gray$mgod;
          border-color: $gray$mgod;
          color: rgba($black, 0.25) $mgod;
        }
      }
    }

    

    &-success {
      background-color: $success $mgod;
      border-color: $success $mgod;
      color: $white$mgod;

      &:hover,
      &:active,
      &:focus {
        background-color: darken($success, 10%) $mgod;
        border-color: darken($success, 10%) $mgod;
        color: $white$mgod;
      }
    }

    &-info {
      background-color: $info $mgod;
      border-color: $info $mgod;
      color: $white$mgod;

      &:hover,
      &:active,
      &:focus {
        background-color: darken($info, 10%) $mgod;
        border-color: darken($info, 10%) $mgod;
        color: $white$mgod;
      }
    }

    &-warning {
      background-color: $warning $mgod;
      border-color: $warning $mgod;
      color: $white$mgod;

      &:hover,
      &:active,
      &:focus {
        background-color: darken($warning, 10%) $mgod;
        border-color: darken($warning, 10%) $mgod;
        color: $white$mgod;
      }
    }

    &-danger {
      background-color: $danger $mgod;
      border-color: $danger $mgod;
      color: $white$mgod;

      &:hover,
      &:active,
      &:focus {
        background-color: darken($danger, 10%) $mgod;
        border-color: darken($danger, 10%) $mgod;
        color: $white$mgod;
      }
    }

    &-default {
      background-color: $gray $mgod;
      border-color: $gray $mgod;
      color: $defaultFontColor;

      &:hover,
      &:active,
      &:focus {
        background-color: $gray $mgod;
        border-color: $gray $mgod;
        color: $primary $mgod;
      }
    }

    &-ghost {
      background-color: $gray $mgod;
      border-color: $gray $mgod;
      color: $defaultFontColor $mgod;

      &:hover,
      &:active,
      &:focus {
        border-color: $primary $mgod;
      }
    }

    &-group {
      .ant-btn {
        &.active {
          color: $white$mgod;
          border-color: $primary $mgod;
          background-color: $primary $mgod;
        }
      }
    }

    &-disabled,
    &.disabled,
    &[disabled] {
      color: rgba($black, 0.25) $mgod;
      &:hover,
      &:active,
      &:focus,
      &::selection {
        background-color: $gray$mgod;
        border-color: $gray$mgod;
        color: rgba($black, 0.25) $mgod;
      }
    }
  }

  &-card {
    transition: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.09);

    // Style table under Card
    .ant {
      &-table {
        &-tbody {
          > tr {
            &:last-child {
              td {
                border-bottom: 1;
              }
            }
          }
        }
      }
    }

    .ant-table-pagination {
      border-top: 1px solid $lightgray;
      padding: 20px 16px;
      margin: 0;
      float: none;
      text-align: center !important;
    }

    &-head {
      &-title {
        font-weight: 600;
      }
    }

    &-action {
      .ant-card-extra {
        color: $primary;

        &:hover {
          color: $primary;
        }
      }

      &.event {
        .ant-card-head-title {
          margin-right: 8px;
          flex: none;
        }

        .ant-card-extra {
          float: none;
          margin: 0;
        }
      }
    }
  }

  &-divider {
    &-clear {
      background: transparent;

      &-top {
        margin-top: 0;
      }

      &-bot {
        margin-bottom: 0;
      }
    }
  }

  &-tag {
    &-action {
      position: absolute;
      top: 10px;
      right: 7px;
      z-index: 1;
      border-radius: 0px 0px 0px 4px;
    }
  }

  &-layout {
    // background: $defaultBackground;

    &-has-sider {
      margin-left: 235px;

      @media (max-width: 767px) {
        margin: 0;
      }
    }

    &-header,
    &-content {
      padding: 0 15px;
      background-color: #fff  !important;
    }

    &-header {
      background: #fff !important;   //$defaultBackground$mgod;
      align-items: center;
      display: flex;
      height: auto;

      span {
        &.title {
          color: $dark;
          margin-right: 8px;
          font-size: 24px;

          line-height: 1;

          @media (min-width: 992px) {
            line-height: normal;
          }
        }
      }
    }

    &-sider {
      position: fixed;
      height: 100vh;
      z-index: 1;
      left: 0;

      &-zero-width-trigger {
        background: $primary$mgod;
        color: $defaultFontColor;
        top: 12px;

        &:hover {
          background: darken($primary, 10%) $mgod;
        }
      }

      .sidebar {
        .ant-menu {
          background: transparent;
          transition: all 0s;

          &-inline,
          &-vertical {
            border-right: 0;
          }

          &:not(.ant-menu-horizontal) {
            .ant-menu {
              &-item {
                &-selected {
                  background-color: $primary;
                }
              }
            }
          }

          &-item {
            margin: 0;
            height: 44px;
            color: $neutral;
            line-height: 44px;
            background-color: transparent;
            transition: none;
            padding-left: 15px$mgod;
            display: flex;
            align-items: center;

            > a {
              color: $defaultFontColor;
            }

            span {
              font-size: 15px;
              font-weight: 600;
            }

            svg {
              path,
              g {
                fill: $neutral;
              }
            }

            .anticon,
            .anticon svg {
              width: 18px$mgod;
              height: auto$mgod;
            }

            &:after {
              content: none;
            }

            &-group {
              &-title {
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 500;
                color: lighten($primary, 25%);
                color: #9fa9ba;
              }
            }

            &-active {
              span {
                color: $neutral;
              }

              svg {
                path,
                g {
                  fill: $primary;
                }
              }
            }

            &-selected {
              background: $primary;
              border-radius: 8px;

              span {
                color: $white;
              }

              i {
                color: $white;
              }

              svg {
                path,
                g {
                  fill: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  &-pagination {
    text-align: center;
    float: none;
    li {
      margin-right: 0;

      &:first-child {
        a {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &:last-child {
        a {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      a {
        color: $primary;

        &:hover {
          border-color: $gray;
        }
      }
    }

    &-item {
      &:not(:first-child),
      &:not(:last-child) {
        border-radius: 0;
      }

      &:hover {
        background-color: #eee;
        border-color: $gray;
      }

      &.ant-pagination-item-active {
        background-color: $primary;

        a {
          color: $white $mgod;
        }

        border-color: $primary $mgod;
      }

      &.ant-pagination-item {
        border-right: 0;

        &-1 {
          border-left: 0;
        }
      }
    }

    &-prev,
    &-next {
      a {
        border-color: $gray;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-table {
    &-thead {
      tr > th {
        opacity: 1;
        padding: 12px 16px 8px;
        background: $white $mgod; 
        border-bottom-color: $gray $mgod;
        // &:hover,
        // &:active,
        // &:focus {
        //   background-color: darken($tableBackground, 10%) $mgod;
        //   border-color: darken($tableBackground, 10%) $mgod;
        // }
      }

      .ant-table-column {
        &-title {
          font-weight: 600;
          color: $neutral;
        }

        &-sorter {
          .anticon {
            color: $gray;
          }
        }

        &-sorter-up.on,
        &-sorter-down.on {
          color: $primary$mgod;
        }
      }
    }

    &-tbody {
      > tr {
        &:hover {
          &:not(.ant-table-expanded-row) {
            > td {
              // background: inherit $mgod;//$tableRowHover $mgod;
              cursor: pointer;
            }
          }
        }
        td {
          padding: 12px 16px;
          border-bottom-color: #ebf0f4 $mgod;
        }

        &:last-child {
          // td {border-bottom: inherit }
        }
      }
    }
  }

  &-switch {
    background-color: lighten($black, 65%);

    &-checked {
      background-color: $primary;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba($primary, 0.2);
    }
  }

  &-modal {
    &-title {
      font-weight: 600;
    }

    // Must set Modal (component) width to "auto"
    @media (min-width: 576px) {
      &-sm {
        &.ant-modal {
          max-width: 500px;
        }
      }
    }

    @media (min-width: 992px) {
      &-md,
      &-lg {
        &.ant-modal {
          max-width: 800px;
        }
      }
    }

    @media (min-width: 1200px) {
      &-lg {
        &.ant-modal {
          max-width: 1140px;
        }
      }
    }
  }

  &-input {
    &:hover {
      color: $primary;
      border-color: $primary;
    }

    &:focus {
      @extend .onFocus;
    }

    &-number {
      &:hover,
      &:focus {
        @extend .onFocus;
      }
    }

    &-affix-wrapper {
      &:hover {
        .ant-input {
          &:not(.ant-input-disabled) {
            border-color: $primary;
          }
        }
      }
    }
  }

  &-form {
    &-explain {
      margin-top: 5px;
    }
  }

  &-checkbox {
    &-input {
      &:focus {
        + .ant-checkbox-inner {
          border-color: $primary;
        }
      }
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: $primary;
      }
    }

    &-checked {
      .ant-checkbox-inner {
        background-color: $primary;
        border-color: $primary;
      }

      &:after {
        border-color: $primary;
      }
    }

    &-indeterminate {
      .ant-checkbox-inner {
        &:after {
          background-color: $primary;
        }
      }
    }
  }

  &-tabs {
    &-tab {
      &-active {
        color: $primary$mgod;
      }

      &:hover,
      &:active,
      &:focus {
        color: darken($primary, 10%) $mgod;
      }
    }

    &-ink {
      &-bar {
        background-color: $primary$mgod;
      }
    }
  }

  &-collapse {
    @include border-radius(4px$mgod);

    > .ant-collapse {
      &-item,
      &-item:last-child {
        @extend .ant-collapse;
        overflow: hidden;
      }
    }
  }
}

.shrinked-form{
  .ant-form-item{
    margin-bottom: 0px !important; 
    &-label{
      line-height: 35px !important;
      label{
        color: rgba(0, 0, 0, 0.65) !important;
      }
    }
    &-control{
      line-height: 35px !important;
    }
  }
  .ant-card{
    margin-bottom: 32px !important;
    &-body{
      overflow: hidden !important;
    }
  }
}

.label-right{
  display: flex;
  flex-direction: row-reverse;
}