.page-header {
  .profile-switch {
    margin-left: auto;
    &-auto {
      margin-right: auto;
    }
    &-fixed {
      margin-right: 150px;
    }
  }

  .deactivated-message {
    background-color: red;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem;
    h2 {
      color: white;
    }
    p {
      margin-bottom: 0px;
    }
  }
}
