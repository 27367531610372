.portrait{
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    gap: 16px;

    &-pagination{
        float: left !important;
        .ant-pagination-item {
            @for $i from 1 through 50 {
               &-#{$i} {
                  display: none;
               }
            }
         }
        .ant-pagination-item-active{
            display: inline-block;
        }
        .ant-pagination-jump-prev{
            display: none;
        }
        .ant-pagination-jump-next{
            display: none;
        }
    }
}
.profile-utility{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    &-metric{
        flex: 1 1 50%;
        .ant-form-item{
            margin-bottom: 0 !important;
        }
    }
    &-switch{
        display: flex;
        text-align: center;
        gap: 4px;
        margin-left: 12px;
        margin-top: 10px;
        align-items: center;
    }
    &-company{
        flex-direction: column;
        display: flex;
        align-items: center;
        gap: 12px;
    }
}