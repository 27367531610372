.inno-card {
  &-title {
    position: absolute;
    left: 20px;
    top: 20px;
    right: 20px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    line-height: 23px;
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    height: 83px;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    //white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &-footer {
    text-align: center;
    padding: 10px;
  }

  &-bottom {
    &-overlay {
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
      width: 100%;
      background: #fff;
      padding: 10px;
      overflow: hidden;
      -webkit-transition: opacity 0.3s;
      transition: opacity 0.3s;

      .actionBtnsLeft {
        margin: 0 0 0 -62px;
        -webkit-transition: margin 0.3s;
        transition: margin 0.3s;
        float: left;
      }

      .actionBtnsRight {
        margin: 0 -130px 0 0;
        -webkit-transition: margin 0.3s;
        transition: margin 0.3s;
        float: right;
      }
    }
  }

  &-tag {
    position: absolute;
    top: 10px;
    right: 7px;
    z-index: 1;
    border-radius: 0px 0px 0px 4px;
  }

  .ant-card-body {
    > div {
      &:first-child {
        // height: 100px;
        span {
          height: 50px;
        }
      }
    }
  }

  .inno-card-overlay {
    opacity: 0;
  }

  &:hover {
    .ant-card-body {
      .inno-card-bottom {
        &-overlay {
          opacity: 1;
          background: $white;

          .actionBtnsLeft {
            margin: 0;
          }

          .actionBtnsRight {
            margin: 0;
          }
        }
      }
    }

    .inno-card-overlay {
      opacity: 1;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }
  }
}

.ant-row {
  .ant-col {
    .inno-card {
      .ant-card-body {
        div {
          &:first-child {
          }
        }
      }
    }
  }
}
