// Common to pages and section
//* {
//  -webkit-font-smoothing: antialiased$mgod;
//  &::first-letter {
//    text-transform: uppercase;
//  }
//}

// Generic Elements Override
body {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica,
    Arial, sans-serif $mgod;
  // background-color: $defaultBackground $mgod;
  overflow-x: hidden;
}

label {
  display: block;
}

.hand {
  cursor: pointer;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text {
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
  &-justify {
    text-align: justify;
  }
  &-align-unset {
    text-align: unset;
  }
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.width-full {
  width: 100%;
}

.height-full {
  height: 100vh;
}

.color {
  &-primary {
    color: $primary;
  }

  &-danger {
    color: $danger;
  }

  &-warning {
    color: $warning;
  }

  &-success {
    color: $success !important;
  }

  &-info {
    color: $info;
  }

  &-dark {
    color: $black;
  }
}


.bg {
  &-primary {
    background: $primary $mgod;
    border-color: $primary $mgod;
    color: $white $mgod;
  }
  &-light-primary {
    background: lighten($primary, 25%) $mgod;
    border-color: lighten($primary, 25%) $mgod;
    color: $white $mgod;
    .ant-btn[disabled]{
      color: #fff !important;
    }
  }

  &-success {
    background: $success $mgod;
    border-color: $success $mgod;
    color: $white $mgod;
  }

  &-warning {
    background: $warning $mgod;
    border-color: $warning $mgod;
    color: $white $mgod;
    &:hover,
    &:active,
    &:focus {
      background-color: darken($warning, 10%) $mgod;
      border-color: darken($warning, 10%) $mgod;
    } 
  }

  &-info {
    background: $info $mgod;
    border-color: $info $mgod;
    color: $white $mgod;
  }

  &-danger {
    background: $danger $mgod;
    border-color: $danger $mgod;
    color: $white $mgod;
    &:hover,
    &:active,
    &:focus {
      background-color: darken($danger, 10%) $mgod;
      border-color: darken($danger, 10%) $mgod;
    }
    tr.ant-table-row-selected {
      td{
        background: $danger $mgod;
        border-color: $danger $mgod;
        color: $white $mgod;
      }
      
    }
    td {
      background: $danger $mgod;
      border-color: $danger $mgod;
      color: $white $mgod;
    }
    tr:hover:not(.ant-table-expanded-row){
      td{
        background: $danger $mgod;
        border-color: $danger $mgod;
        color: $white $mgod;
      }
    }
  }

  &-disabled {
    background: $gray $mgod;
    border-color: $gray $mgod;
    color: $white $mgod;
  }

  &-white {
    background: $white $mgod;
    border-color: $white $mgod;
    color: $neutral $mgod;
  }

  &-neutral {
    background: $neutral $mgod;
    border-color: $neutral $mgod;
    color: $white $mgod;
  }

  &-lightNeutral {
    background: $lightNeutral $mgod;
    border-color: $lightNeutral $mgod;
    color: $white $mgod;
  } 
}

.border {
  &-0 {
    border: 0 $mgod;
  }

  &-solid {
    border-style: solid;
  }
}

.shadow {
  &-s {
    &-0 {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0) $mgod;
    }

    &-1 {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.08) $mgod;
    }

    &-2 {
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.1) $mgod;
    }

    &-3 {
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.09) $mgod;
    }

    &-30 {
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.09) $mgod;
    }
  }

  &-em {
    @extend .shadow-s-1;

    &:hover {
      @extend .shadow-s-2;
    }
  }
}

.table {
  &-header {
    display: flex;
    align-items: center;
  }
}

.search {
  &-bar {
    .btn-invite {
      margin: 0 10px;
    }
  }
}

.card {
  overflow: hidden;
  border-style: solid;

  &-space-default {
    > .ant-card-body {
      padding: 1.25em;
    }
  }

  .ant-card-head {
    margin-bottom: 0;
  }
}

.flex {
  display: flex;

  &-wrap{
    flex-wrap: wrap;
  }
  &-no-wrap{
    flex-wrap: nowrap;
  }
  &-align{
    &-center{
      align-items: center;
      align-content: center;
    }
    &-bottom{
      align-items: baseline;
      align-content: flex-end;
    }
  }
  &-justify{
    &-end{
      justify-content: flex-end;
    }
    &-space-between{
      justify-content: space-between;
    }
  }

  &.flex-center {
    align-items: center;
    justify-content: center;
  }

  &.align-center {
    align-items: center;
  }
  &.align-start{
    align-items: flex-start;
  }
  &.align-end{
    align-items: flex-end;
  }

  &-inline {
    display: inline-flex;
  }

  &.column {
    flex-direction: column;
  }

  &.space-between {
    justify-content: space-between;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;

  > li {
    margin-bottom: 0.75em;
  }
}

.f {
  &-w {
    &-normal {
      font-weight: normal;
    }

    &-bold {
      font-weight: bold;
    }

    &-100 {
      font-weight: 100;
    }

    &-200 {
      font-weight: 200;
    }

    &-300 {
      font-weight: 300;
    }

    &-400 {
      font-weight: 400;
    }

    &-500 {
      font-weight: 500;
    }

    &-600 {
      font-weight: 600;
    }

    &-700 {
      font-weight: 700;
    }

    &-800 {
      font-weight: 800;
    }

    &-900 {
      font-weight: 900;
    }
  }
}

// Checker
.trace {
  &-1 {
    background-color: #4abcad $mgod;
  }

  &-2 {
    background-color: #f8481d $mgod;
  }

  &-2 {
    background-color: #f8b832 $mgod;
  }

  &-4 {
    background-color: #e1dde2 $mgod;
  }
}

// Overflow
.overflow{
  &-x{
    &-auto{
      overflow-x: auto;
    }
    &-hidden{
      overflow-x: hidden;
    }
  }
}
