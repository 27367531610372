// Unique to specific sections/pages
.ant {
  &-spin-spinning {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 20px;
    height: 20px;
  }

  &-radio {
    &-question-choices {
      width: 100%;
      margin-bottom: 17px;

      > div {
        &:last-child {
          @extend .m-b-0;
        }
      }
    }
  }

  &-layout {
    background: #f6faff$mgod;

    &-sider {
      &-light {
        background: transparent$mgod;
      }

      &-zero-width-trigger {
        color: $white$mgod;
      }
    }

    &-content {
      padding: 0;
    }

    &-header {
      line-height: normal;
      // background: red$mgod;
    }
  }

  &-card {
    border-radius: 4px$mgod;

    &-header {
      &-white {
        .ant-card-head {
          background-color: #fff$mgod;
        }
      }
    }

    &-actions {
      &-white {
        .ant-card-actions {
          background-color: #fff$mgod;
        }
      }
    }
    &.actions-bottom {
      .ant-card-actions {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-top: 0;
      }
    }
  }

  &-collapse {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
    background: transparent$mgod;
    border: 0$mgod;
    border: 1px solid #e8e8e8 $mgod;

    &-item {
      background: #fff;
    }

    &-header {
      background: #fff;
    }

    &-content {
      > .ant-collapse-item {
        box-shadow: 0 0 0 !important;
      }
    }

    > .ant-collapse {
      &-item,
      &-item:last-child {
        border: 0$mgod;
      }
    }
  }

  // Dropdown (Client) list
  &-dropdown {
    &-menu {
      &-items {
        padding: 0;

        .ant {
          &-list {
            &-item {
              padding: 12px 20px;
              border-bottom: 1px solid #ddd;

              &:last-of-type {
                border-bottom: 0;
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }

    // Sign Out dropdown
    .ant-menu {
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1) $mgod;
      border-radius: 4px;
    }
  }

  &-list {
    &.sider-sub-list {
      border-bottom: 1px solid #e8e8e8;

      .ant-list {
        &-item {
          &-meta {
            flex: unset;
          }
        }
      }
    }

    &-item {
      &-meta {
        &-selection {
          .ant-list-item-meta-avatar {
            margin-right: 0;
          }

          .ant-list-item-meta-content {
            h4 {
              margin: 0;
            }
          }
        }
      }
    }
  }

  &-modal {
    &-document {
      .ant-modal-content {
        border-radius: 0;

        position: fixed;
        overflow-y: scroll;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
      }
    }
  }

  &-menu {
    &-item {
      width: 100%$mgod;
    }
  }

  &-pagination {
		float: none$mgod;
		text-align: center;

		&-item {
			&:hover {
				a {
					color: $primary;
				}
			}
		}

		&-prev,
		&-next {
			&:focus,
			&:hover {
				a {
					border-color: $primary$mgod;
					color: $primary$mgod;
				}
			}
		}
	}

  &-drawer {
    &-handler {
      .ant {
        &-drawer {
          &-mask {
          }

          &-content {
            &-wrapper {
              box-shadow: 0 0 5em rgba(0, 0, 0, 0.2) $mgod;
              background-color: transparent $mgod;
              // transition: none;
            }
          }

          // &-btn {
          //   color: $neutral;
          //   background-color: #f9f9f9;

          //   border: 0px;
          //   box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5em;
          //   transform: rotate(90deg);
          //   border-bottom-right-radius: 0px;
          //   border-bottom-left-radius: 0px;
          //   &:hover {
          //     color: $primary;
          //   }
          // }
        }
      }

      &.ant-drawer-open {
        .ant {
          &-drawer {
            &-mask {
              // opacity: 0$mgod;
            }
          }
        }
      }
    }
  }
}

.ant-drawer-btn {
  color: $neutral;
  background-color: #f9f9f9;
  z-index: 1001;
  border: 0px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5em;
  transform: rotate(90deg);
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  &:hover {
    color: $primary;
  }
}

.border {
  &-t {
    &-w-3 {
      border-top-width: 3px $mgod;
    }

    &-w-4 {
      border-top-width: 4px $mgod;
    }

    &-w-5 {
      border-top-width: 5px $mgod;
    }
  }
}

.card {
  &-upload {
    border-style: dashed;
    border-color: $gray;

    .ant-card-body {
      .ant-upload {
        display: block;

        .ant {
          &-uploaded {
            background-color: $lightgray $mgod;

            opacity: 0.5;
            transition: 0.5s;

            &:hover {
              opacity: 1;
            }

            &-file {
              opacity: 1;

              .ant-upload-icon {
                opacity: 0;
                transition: 0.5s;
              }

              &:hover {
                .ant-upload-icon {
                  opacity: 1;
                }
              }

              &-video,
              &-image {
                background-color: transparent $mgod;
              }
            }

            &-none {
              // background-color:transparent$mgod
            }

            .ant-upload-icon {
              text-align: center;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

.header {
  &-sub {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-wrapper {
      // background-color: $white $mgod;
      padding-top: 0;
      overflow: hidden;
      border-bottom: 1px solid $lightgray;
    }
  }

  &-bar {
    flex: 1;
    &-actions {
      display: flex;
      align-items: center;
      h1 {
        // line-height: 1.2;
      }
      button {
        line-height: 1.44;
        text-align: center;
      }
    }
  }
}

.document {
  &-editor {
    .ant-modal-content {
      border-radius: 0;
    }
  }
}

#mergeFieldList {
  margin-bottom: 15px;

  .ant-collapse {
    box-shadow: none;
    border-radius: 0$mgod;

    &-item {
      margin-top: 0;
      border-top: 0$mgod;

      border-bottom: 1px solid #e8e8e8$mgod;

      &:last-child {
        border-bottom: none$mgod;
      }
    }
  }
}

.backgroundImageCover {
  background-position: center center$mgod;
  -webkit-background-size: cover$mgod;
  background-size: cover$mgod;
  background-repeat: no-repeat$mgod;
}

.react-pdf__Page__canvas {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
  width: 100% !important;
  height: auto !important;
  border-radius: 4px;
}

.ant-list-empty-text {
  padding: 0;
}

.score {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.scorenumber {
  width: 80px;
  height: 80px;
  background: #328cf9;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.btn-disable {
  cursor: not-allowed;
  pointer-events: none;

  color: #c0c0c0 !important;
  background-color: #ffffff;
}

.e-de-tool-ctnr-properties-pane {
  overflow-y: hidden;
}

.ql-editor {
  padding: 0;
  > *::first-letter {
    text-transform: none;
  }
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 15em;
  padding: 1em;
}

.user {
  &-ui {
  }
}

.ant-form-item-label{
  text-align: left !important;
}


.ant-card-no-padding{
  .ant-card-body{
    padding: 0 !important;
  }
}

.ant-tab-cases{
  .ant-tabs-bar{
    margin: 0 !important;
    font-size: 16px;
    border-bottom: 0px !important;
    .ant-tabs-tab .ant-tabs-tab-active{
      font-size: 16px !important;
      width: 20% !important;
      text-align: center;
      color: $dark !important;
    }
    .ant-table-placeholder{
      border-bottom: 0 !important;
    }
  }
  .ant-tabs-tabpane {
    padding: 16px 16px 0 16px !important;
    background-color: #fff !important;
    border-left: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;

  }
}

.no-pointer-event{
  pointer-events: none;
}
.ant-table-small > .ant-table-content > .ant-table-body {
margin: 0 !important;
}

.ant-table-placeholder{
  z-index: 0 !important;
}

.menu-full-width{
  .ant-menu{
    min-width: 230px;
  }
  li{
    width: 100%
  }
}

.ant-form-item{
  margin-bottom: 4px !important;
}
.label-right{
  .ant-form-item-label{
    text-align: right !important;
  }
}
.basic-detail-upload{
  .ant-upload{
    min-height: 138px !important;
  }
}


.card-padding{
  &-12{
    .ant-card-body{
      padding: 12px !important;
    }
  }
}
.ant-switch-checked{
  background-color: $success !important;
}

.position{
  &-relative{
    position: relative;
  }
  &-absolute{
    position: absolute;
  }
}
.title{
  &-center{
    position: absolute;
    left: 50%;
    top: -12px;
    background-color: $white;
    padding: 0 10px;
    transform: translateX(-50%);
  }
}


.card-container p {
  margin: 0 !important;
}
// .card-container > .ant-tabs-card .ant-tabs-content {
//   height: 120px !important;
//   margin-top: -16px !important;
// }
.card-container .ant-tabs-card .ant-tabs-content .ant-tabs-tabpane {
  // padding: 16px !important;
  background: #fff !important;
}
.card-container .ant-tabs-card .ant-tabs-nav::before {
  display: none !important;
}
.card-container .ant-tabs-card .ant-tabs-tab {
  background: transparent !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  border-top: 1px solid #fff !important;
}
.card-container .ant-tabs-card .ant-tabs-tab-active{
  border: 1px solid #e8e8e8 !important;
  border-bottom: 1px solid #fff !important;
  color: $dark !important;
}

.user-profile-icon{
  font-size: 70px !important;
  margin-top: 6px;
  padding-top: 26px;
}
.table-dropdown{
  width: 100%;
  border: none;
  .ant-select-selection{
    border: none;
    :hover{
      border: none;
    }
  }
}
.matches-name{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-badge-status-dot{
    height: 14px;
    width: 14px;
  }
}

.master-switch {
  background-color: $danger;
}
.master-switch .ant-switch-checked{
  @extend .bg-success;
  border-color: $success;
  :hover{
    @extend .bg-success;
    border-color: $success;
  }
}

.file-list{
  thead{
    tr{
      :last-child{
        border-right: none !important;
        border-top: 1px solid white;
        border-bottom: none !important;
      }
    }
  }
  tbody{
    .ant-table-row{
      :last-child{
        border: none;
      }
    }
  }
  .ant-table-placeholder{
    border-right: none !important;
    border-bottom: none;
  }
}

.attributed-lender {
  tbody{
    .ant-table-row{
      td {
        border-bottom: none
      }
    }

  }
}
.profile-content .has-sub-tabs .ant-tabs-content{
  max-height: none
}

//LOAN TABS
.ant-table-row:has(.hideExpandIcon) .ant-table-row-expand-icon{
  display:none
  
  }


.ant-row{
  .ant-col-12{
    .loan-detail-text-area {
      &::-webkit-input-placeholder { /* WebKit browsers */
        color: red;
        // opacity: 50%;
    }
    
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: red;
        // opacity: 50%;
    
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: red;
        // opacity: 50%;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
        color:red;
        // opacity: 50%;
    }
    
    }
  }
}




.attributed-lender-table {
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
}

.user-icon{
  .anticon-user{
    font-size: 105px !important;
  }
}

.hideNumberPointers{
  .ant-input-number:hover .ant-input-number-handler-wrap{
     display: none !important;
  }
  .ant-input-number-handler-wrap{
    display: none !important;
  }
}

.ant-input[disabled]{
  color: rgba(0,0,0,0.64) !important;
  background-color: #f5f5f5bf !important;
}
.ant-input-number-disabled{
  color: rgba(0,0,0,0.60) !important;
  background-color: #f5f5f5bf !important;
}
.ant-checkbox-wrapper-disabled{
  span{
    color: rgba(0,0,0,0.64) !important;
  }

}
.ant-checkbox-disabled{
  color: rgba(0,0,0,0.57) !important;
}

.logo-text{
  font-family: 'Poppins',sans-serif;
  font-weight: 500;
  letter-spacing: 0.009em;
  text-transform: none;
}

.profile-content{
  .ant-tabs-content{
    overflow-y: auto;
    // max-height: 49vh;
    max-height: calc(65vh - 119px);
    overflow-x: hidden;
  }
}


.profile-content{
    .loan-tabs-table {
      .ant-table-thead{
      .ant-table-row-cell-break-word{
          padding: 0;
          .ant-table-header-column{
            text-align: center;
          }
      }
      .ant-table-expand-icon-th{
        width: 21px;
        padding: 3px 5px;
      }
    }
    .ant-table-tbody{
      .ant-table-row-cell-break-word{
        padding: 3px 5px;
        text-align: center;
        
    }
    .ant-table-row-expand-icon-cell{
      width: 10px;
      padding: 3px 5px;
    }
    }
  }
}

.custom-sorter{
  display: flex;
  flex-direction: column;
  i{
    font-size: 12px !important;
    line-height: 0.5em;
    height: .5em;
  }
  .active{
      color: $primary !important;
    }
  .anticon{
    color: #ddd;
    line-height: 0.5em;
  }
}

.ant-table-header-column{
  width: 100%;
}

.flex-direction{
  &-row{
    flex-direction: row;
  }
  &-col{
    flex-direction: column;
  }
}
.flex-justify{
  &-between{
    justify-content: space-between;
  }
}
.flex-align{
  &-center{
    align-content: center;
  }
}

.flex-1-1{
  flex: 1 1;
}
 
.profile-content 
    .loan-tabs-table 
      .ant-table-thead 
        .ant-table-row-cell-break-word 
          .ant-table-header-column {
  text-align: left;
  padding:3px;
  
}

.profile-content 
    .loan-tabs-table 
      .ant-table-thead 
        .ant-table-row-cell-break-word {
          vertical-align: top !important;
}

.darkred {
 color: #8b0000 !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: $dark !important;
}

.card-title {
  background:transparent !important;
  width:100% !important;
  text-align: center !important;
  top: -25px !important;
}

#customPopupContainer .ant-dropdown {
  z-index: 900 !important;
}

.whiteSpace {
  white-space: pre-line;
}

.loan-criteria-verified {
  background-color: blue; 
  color: white;
  padding: 0px 16px;
  border-radius: 5px 5px 0px 0px;
}

.loan-criteria-lapsed {
  background-color: red;
  color: white;
  padding: 0px 16px;
  border-radius: 5px 5px 0px 0px;
}

.loan-criteria-cleared {
  background-color: grey; 
  color: white;
  padding: 0px 16px;
  border-radius: 5px 5px 0px 0px;
}

.loan-criteria.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  padding-left: 4px;
  padding-right: 4px;
}