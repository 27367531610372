// Border Radius
@mixin border-radius-t($radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-radius-b($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

$colours: (
  "cyan": (
    #b2ebf2 #80deea #4dd0e1 #26c6da #00bcd4 #00acc1
  ),
  "blue": (
    #b2ebf2 #80deea #4dd0e1 #26c6da #00bcd4 #00acc1
  )
);

@each $map in $colours {
  $name: nth($map, 1);
  $list: map-get($colours, $name);

  @for $i from 1 through length($list) {
    .#{$name}-#{$i}00 {
      color: nth($list, $i);
    }
  }
}

$radius: 0;
$radius-end: 90;

.radius {
  @for $i from $radius through $radius-end {
    &-#{$i} {
      @include border-radius($i * 1px$mgod);
    }
  }
}

$fsize: 8;
$end: 72;

@for $i from $fsize through $end {
  .f-s-#{$i} {
    font-size: $i * 1px$mgod;
  }
}

$space: 0;
$space-end: 300;

// width
@for $i from $space through $space-end {
  .w-#{$i} {
    width: $i * 1px$mgod;
  }
}

// height
@for $i from $space through $space-end {
  .h-#{$i} {
    height: $i * 1px$mgod;
  }
}
// flex
.flex-1-1 {
  @for $i from 0 through 100 {
    &-#{$i} {
    flex: 1 1 $i * 1%;
    }
  }
}
.w-percent{
  @for $i from 0 through 100 {
    &-#{$i} {
    width: $i * 1%;
    }
  }
}
.h-percent{
  @for $i from 0 through 100 {
    &-#{$i} {
    height: $i * 1%;
    }
  }
}

.card {
  @for $i from $space through $space-end {
    &-space-#{$i} {
      > .ant-card-head {
        padding-left: $i * 1px$mgod;
        padding-right: $i * 1px$mgod;
      }

      > .ant-card-body {
        padding: $i * 1px$mgod;
      }
    }

    &-space-h-#{$i} {
      > .ant-card-head {
        padding-left: $i * 1px$mgod;
        padding-right: $i * 1px$mgod;
      }
    }

    &-cover {
      &-#{$i} {
        > .ant-card-cover {
          padding: $i * 1px$mgod;
        }
      }
    }
  }
}

@for $i from $space through $space-end {
  // PADDING
  .p-#{$i} {
    padding: $i * 1px$mgod;
  }

  .p-l-#{$i} {
    padding-left: $i * 1px$mgod;
  }

  .p-r-#{$i} {
    padding-right: $i * 1px$mgod;
  }

  .p-t-#{$i} {
    padding-top: $i * 1px$mgod;
  }

  .p-b-#{$i} {
    padding-bottom: $i * 1px$mgod;
  }

  .p-tb-#{$i} {
    padding-top: $i * 1px$mgod;
    padding-bottom: $i * 1px$mgod;
  }

  .p-lr-#{$i} {
    padding-left: $i * 1px$mgod;
    padding-right: $i * 1px$mgod;
  }

  // MARGIN Positive
  .m-#{$i} {
    margin: $i * 1px$mgod;
  }

  .m-l-#{$i} {
    margin-left: $i * 1px$mgod;
  }

  .m-r-#{$i} {
    margin-right: $i * 1px$mgod;
  }

  .m-t-#{$i} {
    margin-top: $i * 1px$mgod;
  }

  .m-b-#{$i} {
    margin-bottom: $i * 1px$mgod;
  }

  .m-tb-#{$i} {
    margin-top: $i * 1px$mgod;
    margin-bottom: $i * 1px$mgod;
  }

  .m-lr-#{$i} {
    margin-left: $i * 1px$mgod;
    margin-right: $i * 1px$mgod;
  }

  .m-l-#{$i} {
    margin-left: $i * 1px$mgod;
  }

  .m-r-#{$i} {
    margin-right: $i * 1px$mgod;
  }

  .m-t-#{$i} {
    margin-top: $i * 1px$mgod;
  }

  .m-b-#{$i} {
    margin-bottom: $i * 1px$mgod;
  }

  .m-tb-#{$i} {
    margin-top: $i * 1px$mgod;
    margin-bottom: $i * 1px$mgod;
  }

  .m-lr-#{$i} {
    margin-left: $i * 1px$mgod;
    margin-right: $i * 1px$mgod;
  }

  // Margin negative
  .m-n-#{$i} {
    margin: -$i * 1px$mgod;
  }
  .m-n-l-#{$i} {
    margin-left: -$i * 1px$mgod;
  }

  .m-n-r-#{$i} {
    margin-right: -$i * 1px$mgod;
  }

  .m-n-t-#{$i} {
    margin-top: -$i * 1px$mgod;
  }

  .m-n-b-#{$i} {
    margin-bottom: -$i * 1px$mgod;
  }

  .m-n-tb-#{$i} {
    margin-top: -$i * 1px$mgod;
    margin-bottom: -$i * 1px$mgod;
  }

  .m-n-lr-#{$i} {
    margin-left: -$i * 1px$mgod;
    margin-right: -$i * 1px$mgod;
  }

  // LINE HEIGHT
  .l-h-#{$i} {
    line-height: $i * 1px;
  }
}

// Flex Gap
$gap: 0;
$gap-end: 90;

@for $i from $gap through $gap-end {
  // PADDING
  .gap-#{$i} {
     gap: $i * 1px $mgod; 
  }
}

// Align Self
.align-self{
  &-center{
     align-self: center;
  }
  &-right {
    align-self: flex-end;
  }
}
