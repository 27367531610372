.slider {
  &-wrapper {
    position: absolute;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    z-index: 5;

    label {
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
  }

  &-menu {
    // &-checkbox {
    //   display: none;
    // }

    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    height: 100%;
    transition: transform 0.35s;
    border-right: 1px solid #eee;

    @extend .shadow-s-2;

    label {
      &.slider-menu-toggle {
        position: absolute;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        top: 180px;
        right: -104px;
        display: block;
        padding: 0;
        background: #fff;
        border: 1px solid #eee;
        border-bottom: 0;
        box-shadow: 3px -26px 33px 0px rgba(0, 0, 0, 0.1);
        padding: 8px 21px;
        transform: rotate(90deg);

        // position: absolute;

        // border-top-right-radius: 4px;
        // border-bottom-right-radius: 4px;

        // top: 135px;
        // right: -32px;
        // width: 32px;
        // height: 200px;

        // display: block;
        // padding: 0;

        // background: #fff;
        // border: 1px solid #eee;
        // border-left-color: #fff;

        // box-shadow: 3px -18px 44px 0px rgba(0, 0, 0, 0.1)

        &:hover {
          color: $primary;
        }

        span {
          transform: rotate(90deg);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 200px;
          text-align: center;
        }
      }
    }
  }
  &-header {
    border-bottom: 1px solid #eee;
  }
  &-content {
  }
}
