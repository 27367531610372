.loan{
    &-filter{
        font-size: 12px !important;
        .ant-card-body{
            padding: 4px 6px;
            .ant-radio-wrapper{
                margin-right: 0;
            }
            .ant-radio{
                font-size: 14px;
                height: 14px;
                width: 14px;
                margin-bottom: 2px;
            } 
            span.ant-radio + * {
                    padding: 0 6px;
                    font-size: 11px;
            } 
        }
    }
}